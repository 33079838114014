import React, { Component } from 'react';
import { H5 } from '@blueprintjs/core';

class SearchInfo extends Component {
  render() {
    const { title, content } = this.props;
    let error = 'Data only present in Dynamo!';
    return (
      <div className="margin-auto">
        <H5 className="card-title">{title}</H5>
        <textarea
          readOnly
          className="card-text dialog-text-area"
          value={JSON.stringify(content, null, '\t')}
        />
        {title === 'Dynamo' && <p className="note-alert-color">{error}</p>}
      </div>
    );
  }
}

export default SearchInfo;
